@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap");

@media screen and (max-width: 400px) {
  #intro,
  #vigseln,
  #info,
  #rsvp,
  #rsvp {
    width: 111%;
  }
}
@keyframes pop {

  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

#root {
  background-color: #ffffff;
}
* {
  font-family: "Playfair Display", serif;
}
h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: #333;
}
a {
  color: #000000 !important;
}

i {
  text-align: center;
}
p {
  font-size: 18px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}
#bas {
  padding: 100px 0;
}
#bas h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#bas h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.pop {
  animation: pop 3s 1;
}
.popQuick {
  animation: pop 2s 1;
}
#bas img {
  width: 520px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#bas p {
  line-height: 24px;
  margin: 30px 0;
}

/* Header Section */
.intro {
  margin-top: 0;
  padding-top: 0;
  display: table;
  width: 100%;
  padding: 0;
  background-color: #e5e5e5;
}

.intro-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
body {
  margin: 0;
  padding: 0;
}

.fixed-width {
  width: 500px; /* Adjust as needed */
  height: auto;
}

.circle-image {
  margin-top: 20px;
  object-fit: cover;
  object-position: center;
  width: 70vw; /* Adjust as needed */
  height: 50vw; /* Adjust as needed */
  max-width: 700px; /* Maximum width of 500 pixels */
  max-height: 500px; /* Maximum height of 500 pixels */
 
  background: url("img/bg.jpeg") center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;

  margin-bottom: 20px; /* Optional: Adds some space between the image and the text */
  /* Add these properties to center the text */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff; /* Change the color as needed */
}

h1.semi-transparent {
  color: rgba(255, 255, 255, 0.7) !important; /* White text with 50% opacity */
}
/* This media query applies when the screen is 768px wide or wider */
@media screen and (min-width: 857px) {
  .circle-image {
    width: 95vw; /* Half screen width on large screens */
    height: 67,86wv; /* Half screen width on large screens */
  }
}
.intro .overlay {
  background: rgba(0, 0, 0, 0.2);
}
.intro h1 {
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}
.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}
.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}
header .intro-text {
  padding-top: 350px;
  padding-bottom: 200px;
  text-align: center;
}

#middag {
  padding: 100px 0;
  background: #f6f6f6;
  overflow: auto;
}
#middag i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}

.alt-title {
  margin-bottom: 70px;
}
.alt-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.full-width-background {
  height: 100%;
  overflow: auto;
}

#middag h2 .text {
  position: relative;
  display: inline-block; /* This is necessary for the ::after to work correctly */
}

.alt-title p {
  font-size: 18px;
}

/* Testimonials Section */
#resandes {
  padding: 100px 0;
  background: #f6f6f6;
}
#resandes i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
.resande {
  position: relative;
  padding: 20px;
}
.resande-image {
  float: left;
  margin-right: 15px;
}
.resande-image,
.resande-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.resande-content {
  position: relative;
  overflow: hidden;
}
.resande-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}
.resande-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
}

.resande-title {
  margin-bottom: 70px;
}
.resande-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

#resandes h2 .text {
  position: relative;
  display: inline-block; /* This is necessary for the ::after to work correctly */
}

.resande-title p {
  font-size: 18px;
}

#footer {
  padding: 100px 0;
}

.countdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 2em;
  background-color: #f5f5f5;
  border: 2px solid #333;
  padding: 20px;
  border-radius: 10px;
}

.countdown-item {
  text-align: center;
}

.countdown-item span {
  display: block;
  font-size: 0.5em;
  color: #333;
}

.welcome-sign {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 1000;
  animation: fadeOut 2s linear forwards;
}

.welcome-text {
  font-family: 'Cursive', 'Sans-serif';
  font-size: 3em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

